<template>
	<div class="maxBox" :class="!isMobile ? 'pcbox' : ''">
		<h1>고객센터 연락처</h1>
		<div class="textDes">
			<p>제품마다 대응되는 KAKAO ID로 직접 고객센터에 연락할 수 있습니다. </p>
			<br>
			<br>
			<p>혹은 hongdenglonghk0330@gmail.com로(으로) 메일을 보내주시면 빠른시기내로 문제를 해결해드리겠습니다.</p>
		</div>
	</div>
</template>

<script setup>
	import { getCurrentInstance,onMounted,ref,computed } from 'vue'
	import useConfigStore from '@/store/modules/config'
	const isMobile = computed(() => {
		return useConfigStore().isMobile
	})
</script>
<style lang="less">
  @import '@/assets/styles/common';
</style>
<style lang="less" scoped>
	.maxBox{flex-wrap: wrap;}
	h1{width: 100%;}
</style>
